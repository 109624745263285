import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  ApolloLink,
  HttpLink
} from '@apollo/client' 
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';
import 'react-spring-modal/dist/index.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'components/Scrollbar/scrollbar.css'
import './theme/global.css'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL
})

const anymousToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYW5vbnltb3VzIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFub255bW91cyJ9LCJpYXQiOjE1OTMzNTE4NTAsImV4cCI6MTAyMzMyNjU0NTB9.mdv-EooD8pxbIAD1eHtpqs6Ro906o2rbNT9UonvXkjI'
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: localStorage.getItem('dogally_user_token') !== null ? 'Bearer ' + localStorage.getItem('dogally_user_token') : anymousToken,
    }
  })
  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // cache: cache,
  link: concat(authMiddleware, httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

function App() {
  const engine = new Styletron()

  return (
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <BrowserRouter>
            <ToastProvider autoDismiss autoDismissTimeout={4500}>
              <Routes />
            </ToastProvider>
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
