import React from 'react';
import { getCookie, removeCookie, setCookie } from '../settings/useSession';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

const isBrowser = typeof window !== 'undefined';

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('dogally_user_token');
  if (token) return true;
  return false;
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());

  function authenticate({ username, token }) {
    isBrowser && setCookie("shop_token", token);
    localStorage.setItem('dogally_user_token', `${token}`);
    makeAuthenticated(true);
    localStorage.setItem('dogally_user_email', `${username}`);
  }
  function signout() {
    localStorage.removeItem('dogally_user_email');
    makeAuthenticated(false);
    localStorage.removeItem('dogally_user_token');
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
