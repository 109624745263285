// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/'
export const PRODUCTS = '/products'
export const CATEGORY = '/category'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const ORDERS = '/orders'
export const CUSTOMERS = '/customers'
export const COUPONS = '/coupons'
export const SETTINGS = '/settings'
export const STAFF_MEMBERS = '/staff-members'
export const SITE_SETTINGS = '/site-settings'
export const PAGES = '/pages'
export const BLOGS = '/blogs'
export const SLIDERS = '/sliders'
export const REACT_APP_API_IMAGE_URL = 'https://imgpush.duzadamlar.com/'
export const REACT_APP_API_IMAGE_URL2 = 'https://imgpush.duzadamlar.com/'
export const REACT_APP_API_UPLOAD_URL =
  'https://nodered.duzadamlar.com/put-image'
export const DEFAULT_IMAGE = 'https://imgpush.duzadamlar.com/9ZInQ.jpg'
export const TOKEN =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYW5vbnltb3VzIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFub255bW91cyJ9LCJpYXQiOjE1OTMzNTE4NTAsImV4cCI6MTAyMzMyNjU0NTB9.mdv-EooD8pxbIAD1eHtpqs6Ro906o2rbNT9UonvXkjI'
export const STATUS_TEXT = {
  0: 'Sipariş Verildi',
  1: 'Sipariş Hazırlanıyor',
  2: 'Sipariş Yola Çıktı',
  3: 'Teslim Edildi',
  4: 'Sipariş İptal Edildi',
}
export const PAYMENT_TYPE_TEXT = {
  0: 'Nakit',
  1: 'Kredi veya Banka Kartı',
  2: 'Kapıda Ödeme',
  3: 'Havale',
}
export const PAYMENT_TYPES = [
  {
    id: 0,
    is_valid: true,
    title: 'Nakit',
    support_types: [],
  },
  {
    id: 1,
    is_valid: true,
    title: 'Kredi veya Banka Kartı',
    support_types: [
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/Visa.svg',
        title: 'Visa',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/VisaElectron.svg',
        title: 'Visa Electron',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/MasterCard.svg',
        title: 'Master Card',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/Maestro.svg',
        title: 'Maestro',
      },
    ],
  },
  {
    id: 2,
    is_valid: true,
    title: 'Kapıda Ödeme',
    support_types: [],
  },
  {
    id: 3,
    is_valid: true,
    title: 'Havale',
    support_types: [
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/Visa.svg',
        title: 'Garanti Bankası',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/VisaElectron.svg',
        title: 'Visa Electron',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/MasterCard.svg',
        title: 'Master Card',
      },
      {
        cover_image: 'https://st-hummel.mncdn.com/Content/img/Maestro.svg',
        title: 'Maestro',
      },
    ],
  },
]
export const DISTRICTS = [
  { value: 'Altayçeşme Mahallesi̇', name: 'Altayçeşme Mahallesi̇', id: '1' },
  { value: 'Altintepe Mahallesi̇', name: 'Altintepe Mahallesi̇', id: '1' },
  { value: 'Aydinevler Mahallesi̇', name: 'Aydinevler Mahallesi̇', id: '1' },
  { value: 'Bağlarbaşi Mahallesi̇', name: 'Bağlarbaşi Mahallesi̇', id: '1' },
  { value: 'Başibüyük Mahallesi̇', name: 'Başibüyük Mahallesi̇', id: '1' },
  {
    value: 'Büyükbakkalköy Mahallesi̇',
    name: 'Büyükbakkalköy Mahallesi̇',
    id: '1',
  },
  { value: 'Cevi̇zli̇ Mahallesi̇', name: 'Cevi̇zli̇ Mahallesi̇', id: '1' },
  { value: 'Çinar Mahallesi̇', name: 'Çinar Mahallesi̇', id: '1' },
  { value: 'Esenkent Mahallesi̇', name: 'Esenkent Mahallesi̇', id: '1' },
  { value: 'Feyzullah Mahallesi̇', name: 'Feyzullah Mahallesi̇', id: '1' },
  { value: 'Findikli Mahallesi̇', name: 'Findikli Mahallesi̇', id: '1' },
  { value: 'Gi̇rne Mahallesi̇', name: 'Gi̇rne Mahallesi̇', id: '1' },
  { value: 'Gülensu Mahallesi̇', name: 'Gülensu Mahallesi̇', id: '1' },
  { value: 'Gülsuyu Mahallesi̇', name: 'Gülsuyu Mahallesi̇', id: '1' },
  { value: 'İdealtepe Mahallesi̇', name: 'İdealtepe Mahallesi̇', id: '1' },
  {
    value: 'Küçükyali Merkez Mahallesi̇',
    name: 'Küçükyali Merkez Mahallesi',
    id: '1',
  },
  { value: 'Yali Mahallesi̇', name: 'Yali Mahallesi̇', id: '1' },
  { value: 'Zümrütevler Mahallesi̇', name: 'Zümrütevler Mahallesi̇', id: '1' },
]
export const SITE_NAME = 'Organikal'
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '₺'
